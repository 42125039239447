import React from 'react'
import styled from 'styled-components'
import { media } from 'utils/Media'
import GetImage from 'components/functional/getImage'

const HomeClientsStyles = styled.section`
  width: 100%;
  margin-top: 2rem;
  padding-bottom: 2rem;
  background-color: var(--white);
  .clients {
    width: 85%;
    margin: var(--auto);
    max-width: var(--maxWidth);
    h3 {
      margin-bottom: 2.5rem;
      color: var(--cyan);
      text-transform: uppercase;
      font-weight: 300;
      font-family: var(--PoppinsBlack);
      font-size: 1.5rem;
      width: 100%;
      text-align: center;
      @media ${media.md} {
        font-size: 2.25rem;
      }
    }
    &--grid {
      display: grid;
      grid-template-columns: repeat(2, 1fr);

      &--img {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0.95rem 0.5rem;

        &:nth-child(even) {
          border-bottom: solid black 1px;
        }
        &:nth-child(odd) {
          border-bottom: solid black 1px;
          border-right: solid black 1px;
        }
        &:nth-child(29),
        &:nth-child(30) {
          border-bottom: none;
        }
      }
      @media ${media.md} {
        grid-template-columns: repeat(6, 1fr);
        justify-content: center;
        &--img {

          &:nth-child(even),
          &:nth-child(odd) {
            border: none;
          }

          &:nth-child(1),
          &:nth-child(2),
          &:nth-child(3),
          &:nth-child(4),
          &:nth-child(5) {
            border-bottom: rgba(0, 0, 0, 0.25) solid 2px;
          }
          &:nth-child(1),
          &:nth-child(2),
          &:nth-child(3),
          &:nth-child(4) {
            border-right: rgba(0, 0, 0, 0.25) solid 2px;
          }
          &:nth-child(6),
          &:nth-child(7),
          &:nth-child(8),
          &:nth-child(9),
          &:nth-child(10) {
            border-bottom: rgba(0, 0, 0, 0.25) solid 2px;
          }
          &:nth-child(5),
          &:nth-child(7),
          &:nth-child(8),
          &:nth-child(9) {
            border-right: rgba(0, 0, 0, 0.25) solid 2px;
          }
          &:nth-child(11),
          &:nth-child(12),
          &:nth-child(13),
          &:nth-child(14),
          &:nth-child(15) {
            border-bottom: rgba(0, 0, 0, 0.25) solid 2px;
          }
          &:nth-child(10),
          &:nth-child(11),
          &:nth-child(13),
          &:nth-child(14) {
            border-right: rgba(0, 0, 0, 0.25) solid 2px;
          }
          &:nth-child(16),
          &:nth-child(17),
          &:nth-child(18),
          &:nth-child(19),
          &:nth-child(20),
          &:nth-child(21),
          &:nth-child(22),
          &:nth-child(23),
          &:nth-child(24)
          {
            border-bottom: rgba(0, 0, 0, 0.25) solid 2px;
          }
          &:nth-child(16),
          &:nth-child(15),
          &:nth-child(17),
          &:nth-child(19),
          &:nth-child(20) {
            border-right: rgba(0, 0, 0, 0.25) solid 2px;
          }
          &:nth-child(21),
          &:nth-child(22),
          &:nth-child(23),
          &:nth-child(26), 
          &:nth-child(25), 
          &:nth-child(27),
          &:nth-child(28),
          &:nth-child(29),
          &:nth-child(31),
          &:nth-child(32),
          &:nth-child(33),
          &:nth-child(34),
          &:nth-child(35)
          {
            border-right: rgba(0, 0, 0, 0.25) solid 2px;
          }
          /* &:nth-child(26)
          {
            border-left: rgba(0, 0, 0, 0.25) solid 2px;
          } */
          /* &:nth-last-child(2) {
            grid-column-start: 2;
          } */
          /* &:nth-child(25) {
            padding: 1.25rem 0 0 2rem;
          } */
          .gatsby-image-wrapper {
            max-width: 185px;
            margin: 0;
            /* max-height: 100px; */
          }
        }
      }
    }
  }
  &--img {
    width: 100%;
    &--mobile {
      height: 100%;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        object-fit: contain !important;
      }
    }
    &--desktop {
      display: none;
    }
    @media ${media.sm} {
      &--mobile {
        display: none;
      }
      &--desktop {
        display: block;
      }
    }
  }
  @media ${media.md} {
    margin-top: 2rem;
    h3 {
      padding-left: 4rem;
    }
  }
`
const HomeClients = ({ data }) => (
  <HomeClientsStyles>
    <div className="clients">
      <h3>
        Just a few of the clients we love to work with
      </h3>
      <div className="clients--grid">
        {data.map((single, x) => (
          <div className="clients--grid--img" key={x}>
            <GetImage
              data={single.img}
              alt={`${single.client} | Dewynters`}
              key={x}
            />
          </div>
        ))}
      </div>
    </div>
  </HomeClientsStyles>
)

export default HomeClients
