import React from 'react'
import styled from 'styled-components'
import ArrowIcon from 'svgs/arrow.svg'
import { media } from 'utils/Media'

const HomeIntroStyles = styled.section`
  width: 100%;
  background-color: var(--white);
  .intro {
    width: 85%;
    margin: var(--auto);
    padding: 2.5rem 0 0rem 0;
    max-width: var(--maxWidth);
    h2 {
      font-size: 2rem;
      line-height: 1.25;
      font-family: var(--Poppins-Medium);
      text-align: center;
    }
    p {
      margin-top: 1.25rem;
      text-align: center;
    }
    &__arrow {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 1rem;
      svg {
        fill: var(--black);
        height: 100px;
        width: auto;
      }
    }
    @media ${media.s} {
      p {
        margin-top: 1rem;
      }
    }
    @media ${media.sm} {
      .tablet-display-none {
        display: none;
      }
    }
    @media ${media.xl} {
      width: 100%;
      max-width: 950px;
    }
  }
`
const HomeIntro = () => (
  <HomeIntroStyles>
    <div className="intro">
      <h2 className="d-nd">
        The integrated advertising agency for live entertainment
      </h2>
      <div className="intro__arrow tablet-display-none">
        <ArrowIcon />
      </div>
      <p>
        Our unique blend of creativity and cultural insights produces brand
        campaigns that are as unique as you are. And they need to be, too. It’s
        a non-stop world out there and it’s a challenge to be seen and heard
        above all the noise. But with a world of passion, experience and
        know-how, we’ll place you right at the centre of everyone’s
        attention...and keep you there.
      </p>
    </div>
  </HomeIntroStyles>
)

export default HomeIntro
