import React, { useState, useEffect } from 'react'
import styled from 'styled-components'

import {media} from 'utils/Media'

const StyledFlocker = styled.section`
    width: 90%;
    margin: 0 auto;
    max-width: 1440px;
    h3 {
      margin-top: 3rem;
      margin-bottom: 2.5rem;
      color: var(--cyan);
      text-transform: uppercase;
      font-weight: 300;
      font-family: var(--PoppinsBlack);
      font-size: 2.1rem;
      width: 100%;
      text-align: center;
      @media ${media.md} {
        font-size: 2.4rem;
      }
    }
`
export default function Flocker() {
  const [scriptLoaded, setScriptLoaded] = useState(false);
  const scriptId = "flockler-script";
  const embedContainerId = "flockler-embed-195b9a0d14903876ac9907500660dcdd";

  useEffect(() => {
    // Remove the script if it already exists (for Gatsby navigation)
    const existingScript = document.getElementById(scriptId);
    if (existingScript) {
      existingScript.remove();
      setScriptLoaded(false); // Reset script load state
    }

    // Clear previous content inside embed container to ensure proper reloading
    const embedContainer = document.getElementById(embedContainerId);
    if (embedContainer) {
      embedContainer.innerHTML = ""; // Reset container
    }

    // Load script again
    if (!scriptLoaded) {
      const tag = document.createElement('script');
      tag.id = scriptId;
      tag.async = true;
      tag.src = 'https://plugins.flockler.com/embed/194bbb805da0617ba36fdc7ad0f4c5b2/195b9a0d14903876ac9907500660dcdd';
      tag.onload = () => setScriptLoaded(true); // Mark script as loaded
      document.body.appendChild(tag);
    }
  }, []);

  return (
    <StyledFlocker>
      <h3>Latest</h3>
      <div id={embedContainerId}></div>
    </StyledFlocker>
  )
}