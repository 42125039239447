import React from 'react'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import styled from 'styled-components'

const ImageStyled = styled(GatsbyImage)`
  margin-bottom: .5rem;
`

const GetImage = ({ data, alt }) => {
  const image = getImage(data)
  return <ImageStyled image={image} alt={alt} />
}

export default GetImage
