import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import * as React from 'react'

import Layout from 'components/layout'
import HomeIntro from 'components/index/homeIntro'

import MainNav from 'components/functional/mainNav'
import MainHeader from 'components/global/mainHeader'
import HomeClients from 'components/index/homeClients'
import ArrowIcon from 'svgs/arrow.svg'
import Flocker from '../components/index/flocker'

const metaData = {
  title: 'Home | D E W Y N T E R S',
}

function IndexPage({ data }) {
  const sliderData = data.Slider.edges
  const Logos = data.Logos.nodes

  return (
    <Layout title={metaData.title}>
      <MainNav alt />
      <MainHeader>
        <h1>
          <span>
            We’re original<span className="dot">.</span>
          </span>
          <br />
          <span>
            You’re unique<span className="dot">.</span>
          </span>
          <br />
          <span>
            Let’s do different<span className="dot">.</span>
          </span>
        </h1>
        <p className="m-nd">
          The integrated advertising agency for live entertainment
        </p>
        <div className="header-arrow">
          <ArrowIcon />
        </div>
      </MainHeader>
      <HomeIntro />
      <Flocker />
      <HomeClients data={Logos} />
      {/* <HomeRecent /> */}
    </Layout>
  )
}

IndexPage.propTypes = {
  data: PropTypes.shape({
    Meta: PropTypes.shape({
      nodes: PropTypes.any,
    }),
    Slider: PropTypes.shape({
      edges: PropTypes.any,
    }),
  }),
}

export default IndexPage

export const indexQuery = graphql`
  query IndexQuery {
    Slider: allIndexSliderJson {
      edges {
        node {
          text
          title
          href
          img {
            alt
            src {
              childImageSharp {
                gatsbyImageData(
                  width: 800
                  placeholder: BLURRED
                  layout: FULL_WIDTH
                  formats: [AUTO, WEBP, AVIF]
                )
              }
            }
          }
          mobimage {
            alt
            src {
              childImageSharp {
                gatsbyImageData(
                  width: 800
                  placeholder: BLURRED
                  layout: FULL_WIDTH
                  formats: [AUTO, WEBP, AVIF]
                )
              }
            }
          }
        }
      }
    }
    Logos: allClientlogosJson {
      nodes {
        client
        img {
          childImageSharp {
            gatsbyImageData(
              width: 250
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
      }
    }
  }
`
